import { event, plugin } from 'react-ga'
import { CartLineItem } from 'cart/types'
import ReactGA from 'react-ga4'

declare global {
	interface Window {
		dataLayer?: any
	}
}

export const gaAddToCart = (item: CartLineItem) => {
	plugin.execute('ec', 'addProduct', {
		id: item.id,
		name: item.productTitle,
		variant: item.variantTitle,
		price: item.price,
	})
	plugin.execute('ec', 'setAction', 'add')

	event({ category: 'Ecommerce', action: 'Add to Cart', label: 'Add to Cart' })
	ReactGA.event({ category: 'Ecommerce', action: 'Add to Cart', label: 'Add to Cart' })
}

export const gaClickSocialMedia = (site) => {
	event({
		category: 'Social Media',
		action: `Clicked on ${site} icon in footer`,
	})
	ReactGA.event({
		category: 'Social Media',
		action: `Clicked on ${site} icon in footer`,
	})
}

export const gaTrackClickLink = (to) => {
	event({
		category: 'Click',
		action: 'Clicked Button on: ' + window.location.href + ' - to:' + to,
	})
	ReactGA.event({
		category: 'Click',
		action: 'Clicked Button on: ' + window.location.href + ' - to:' + to,
	})
}

export const gaTrackEmailSubscribe = (location) => {
	event({
		category: 'Email',
		action: 'Email subscribed at ' + location,
	})
	ReactGA.event({
		category: 'Email',
		action: 'Email subscribed at ' + location,
	})
}

export const gaExpandFAQ = (question) => {
	event({
		category: 'FAQ',
		action: 'Expanded for: ' + question,
	})
	ReactGA.event({
		category: 'FAQ',
		action: 'Expanded for: ' + question,
	})
}

export const gaExpandCart = () => {
	event({
		category: 'Cart',
		action: 'Cart opened on: ' + window.location.href,
	})
	ReactGA.event({
		category: 'Cart',
		action: 'Cart opened on: ' + window.location.href,
	})
}

export const gaPlayVideo = () => {
	event({
		category: 'Video',
		action: 'Played video on: ' + window.location.href,
	})
	ReactGA.event({
		category: 'Video',
		action: 'Played video on: ' + window.location.href,
	})
}

export const gaCheckoutInitiated = () => {
	event({ category: 'Ecommerce', action: 'Checkout', label: 'Initiated' })
	ReactGA.event({ category: 'Ecommerce', action: 'Checkout', label: 'Initiated' })
}

export const GTMCheckoutInitiated = () => {
	try {
		window.dataLayer.push({ event: 'react.checkout_intiated' })
	} catch (e) {
		console.log(e)
	}
}

export const GTMEmailSubscribe = () => {
	try {
		window.dataLayer.push({ event: 'react.email_subscribe' })
	} catch (e) {
		console.log(e)
	}
}

export const gaEmailCapSubscribeGoalCompletion = () => {
	event({
		category: 'Email',
		action: 'Subscribe',
		label: 'Email Cap',
		value: 1,
	})
}

export const checkoutComplete = () => {
	try {
		window.dataLayer.push({ event: 'shopify.checkout_complete' })
	} catch (e) {
		console.log(e)
	}
}
