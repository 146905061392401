import * as React from 'react'
import { ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { Header } from 'sections/Header'
import { ThemeProvider } from 'styled-components'
import theme from 'theme/index'
import PageWrapper from 'components/PageWrapper'
import { trackPageViewed } from 'events/index'
import { pageViewedPages, PageViewedType } from 'constants/events'
import { RootStore } from 'stores/index'
import { CartProps } from 'sections/Cart/Cart'
import dynamic from 'next/dynamic'
import { ClientOnly } from 'components/ClientOnly'
import { Footer } from 'components/Footer_LEGACY'
import { EMAIL_SMS_DISCOUNT_AMOUNT, EMAIL_SMS_DISCOUNT_CODE, MEMBERS_DISCOUNT_CODE } from 'stripe_lib/discounts'
import { PriceManager } from 'prices'

interface MainProps {
	children?: ReactNode | undefined
	classes?: { [name: string]: string }
	rootStore?: RootStore
	className?: any
	inverted?: boolean
	hideHeader?: boolean
	hideCart?: boolean
}

interface MainState {
	timeoutIds: number[]
	timeoutPages: PageViewedType[]
	pageViewStartTime: number
}

declare global {
	interface Window {
		yotpo: any
		RB: any
		ga: any
	}
}

const refreshAffirm = () => {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.affirm?.ui?.refresh?.()
	} catch (e: any) {
		console.error(e.message)
	}
}

const CartLazy = dynamic<CartProps>(() => import('sections/Cart').then((module) => module.Cart), {
	ssr: false,
})

class MainComponent extends React.Component<MainProps, MainState> {
	constructor(props: MainProps) {
		super(props)

		this.state = {
			timeoutIds: [],
			timeoutPages: [],
			pageViewStartTime: null,
		}

		this.initPageViewedTimer = this.initPageViewedTimer.bind(this)
		this.stopPageViewTimer = this.stopPageViewTimer.bind(this)
	}

	componentDidMount() {
		const { cartStoreNew, navStore, timerStore } = this.props.rootStore!

		const hash = window.location.hash
		timerStore.init()

		cartStoreNew.setShowInterstitial(false)

		if (hash === '#cart') {
			cartStoreNew.toggleCartOpen()
		}

		this.initPageViewedTimer()
		window.addEventListener('focus', this.initPageViewedTimer)
		window.addEventListener('blur', this.stopPageViewTimer)

		document.getElementById('__next').classList.add('__next')

		//trackPageView()
		navStore.setRoute()

		refreshAffirm()
	}

	stopPageViewTimer() {
		const { timeoutIds, timeoutPages, pageViewStartTime } = this.state
		for (let i = 0; i < timeoutIds.length; i++) {
			const pageTime = JSON.parse(sessionStorage.getItem(`PAGE_VIEWED_${timeoutPages[i]}`))
			if (pageTime <= 0) {
				continue
			}
			const diffTime = Date.now() - pageViewStartTime
			const newTime = pageTime - diffTime
			sessionStorage.setItem(`PAGE_VIEWED_${timeoutPages[i]}`, JSON.stringify(newTime))
			clearTimeout(timeoutIds[i])
		}
	}

	initPageViewedTimer() {
		let path = document.location.pathname
		if (path.substr(-1) === '/') {
			path = path.substring(0, path.length - 1)
		}

		const timeoutIds = []
		const timeoutPages = []
		const pageViewStartTime = Date.now()
		for (const page of pageViewedPages) {
			if (!page.pages.includes(path)) {
				continue
			}
			const pageTimeLeftStr = sessionStorage.getItem(`PAGE_VIEWED_${page.type}`)
			const timeLeft = pageTimeLeftStr ? JSON.parse(pageTimeLeftStr) : page.timeMs
			if (timeLeft <= 0) {
				continue
			}
			sessionStorage.setItem(`PAGE_VIEWED_${page.type}`, JSON.stringify(timeLeft))
			const timeoutId = setTimeout(() => {
				trackPageViewed(page.eventName, page.type)
				sessionStorage.setItem(`PAGE_VIEWED_${page.type}`, '-1')
			}, timeLeft)
			timeoutIds.push(timeoutId)
			timeoutPages.push(page.type)
		}

		this.setState({
			timeoutIds,
			timeoutPages,
			pageViewStartTime,
		})
	}

	componentWillUnmount() {
		const { timerStore } = this.props.rootStore

		this.stopPageViewTimer()

		if (timerStore.pageViewIntervalExists) {
			timerStore.stop()
		}

		document.getElementById('__next').classList.remove('__next')
		window.removeEventListener('focus', this.initPageViewedTimer)
		window.removeEventListener('blur', this.stopPageViewTimer)
	}

	render() {
		const { children, inverted, hideHeader, hideCart, rootStore } = this.props

		const { settingsStore, priceStore } = rootStore
		const settings = settingsStore.settings

		const isVanity = settingsStore.isVanity
		const amount = PriceManager.convertToCurrency(EMAIL_SMS_DISCOUNT_AMOUNT, PriceManager.getRegionCurrency(settingsStore.currentRegion))
		const totalAmount = isVanity ? priceStore.totalProductDiscountNumber : amount + priceStore.totalProductDiscountNumber

		const hideEmail = settings.promoCode === EMAIL_SMS_DISCOUNT_CODE.toLowerCase() || settings.promoCode.toUpperCase() === MEMBERS_DISCOUNT_CODE

		return (
			<ThemeProvider theme={theme}>
				<div style={{ backgroundColor: inverted ? 'white' : 'black', color: inverted ? 'black' : 'white' }}>
					{!hideHeader && <Header />}
					<PageWrapper>{children}</PageWrapper>
					{!hideCart && <CartLazy />}
					<Footer
						region={settingsStore.currentRegion}
						baseDiscountAmount={totalAmount}
						hideEmail={hideEmail}
						rootStore={rootStore}
					/>
					<ClientOnly>
						<div
							style={{ position: 'fixed', top: 0 }}
							id={'client'}
							data-testid={'client'}
						/>
					</ClientOnly>
				</div>
			</ThemeProvider>
		)
	}
}

export const Main = inject('rootStore')(observer(MainComponent))

export default Main
