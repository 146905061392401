import cx from 'classnames'
import styles from './Heros.module.scss'
import { Heading } from 'components/basic/Heading'
import { P } from 'components/basic/P'
import { ImgBackground, Source } from 'components/basic/Img'

export const FullHeroWithQuote = (props: { title: string; subtitle: string[]; sources: Source[]; fullBackground?: boolean }) => {
	return (
		<div className={cx(styles.full_hero)}>
			<ImgBackground
				sources={props.sources}
				lazy={false}
				src=""
				alt=""
			>
				<div className={cx('inner-paddings-x-new inner-paddings-y', styles.content)}>
					<Heading
						title={props.title}
						titleClass={cx('h4', styles.title)}
						type="h1"
					/>
					<P
						paragraphs={props.subtitle}
						paragraphClass={cx('h5', styles.subtitle)}
					/>
				</div>
			</ImgBackground>
		</div>
	)
}
