import { useEffect, useState } from 'react'

/**
 * Hook that will return the direction of window scroll on direction change
 * @param scrollThreshold The amount of distance that needs to be covered by cumulative scrolls before the direction changes.
 * If you are using this hook with an element that **animates** its height (collapsing promo bar), this value _must_ be strictly larger than the largest height difference between states.
 * Otherwise, the animation itself will trigger scroll direction changes, likely breaking the animation/state.
 */
export const useScrollDirection = (scrollThreshold: number) => {
	const [scrollDirection, setScrollDirection] = useState('')

	useEffect(() => {
		const threshold = scrollThreshold
		let prevScroll = window.scrollY
		let ticking = false

		const updateScrollDirection = () => {
			const scroll = window.scrollY

			if (Math.abs(scroll - prevScroll) < threshold) {
				ticking = false
				return
			}
			setScrollDirection(scroll > prevScroll ? 'down' : 'up')
			prevScroll = scroll > 0 ? scroll : 0
			ticking = false
		}

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDirection)
				ticking = true
			}
		}

		window.addEventListener('scroll', onScroll)

		return () => window.removeEventListener('scroll', onScroll)
	}, [scrollDirection])

	return scrollDirection
}
