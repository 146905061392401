const getLuminance = (r: number, g: number, b: number): number => {
	const a = [r, g, b].map(function (v) {
		v /= 255
		return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
	})
	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

/**
 * Get the contrast ratio between two colors, WCAG notes the following contrast recommendations: 4.5:1 for normal text and 3:1 for large text
 * @param color1
 * @param color2
 */
const getContrastRatio = (color1: [number, number, number], color2: [number, number, number]): number => {
	const luminance1 = getLuminance(color1[0], color1[1], color1[2])
	const luminance2 = getLuminance(color2[0], color2[1], color2[2])
	const brightest = Math.max(luminance1, luminance2)
	const darkest = Math.min(luminance1, luminance2)
	return (brightest + 0.05) / (darkest + 0.05)
}

const hexToRgb = (hex: string): [number, number, number] => {
	if (hex.startsWith('#')) {
		hex = hex.substring(1)
	}
	const bigint = parseInt(hex, 16)
	const r = (bigint >> 16) & 255
	const g = (bigint >> 8) & 255
	const b = bigint & 255

	return [r, g, b]
}

export const getContrastRatioHex = (hex1: string, hex2: string): number => {
	const rgb1 = hexToRgb(hex1)
	const rgb2 = hexToRgb(hex2)
	return getContrastRatio(rgb1, rgb2)
}
