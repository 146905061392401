import Stripe from 'stripe'
import dotenv from 'dotenv'

export enum StripeEnvKeys {
	STRIPE_ADMIN_KEY = 'STRIPE_ADMIN_KEY',
	STRIPE_ADMIN_PROD_KEY = 'STRIPE_ADMIN_PROD_KEY',
}

// only for branches: vercel and main - never allow Stripe TEST to creep into production
export const IS_PROD_ENV = false // process.env.NEXT_PUBLIC_IS_PROD === 'true' || true

export const IS_AFFIRM_SANDBOX = !IS_PROD_ENV

export const STRIPE_KEY = IS_PROD_ENV ? (StripeEnvKeys.STRIPE_ADMIN_PROD_KEY as any) : (StripeEnvKeys.STRIPE_ADMIN_KEY as any)

export const STRIPE_ADMIN_KEY = process.env[STRIPE_KEY]

export const STRIPE_CLIENT_KEY =
	STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY
		? 'pk_live_51L893zDptqM2bn1xNXytaS2qEQL35k7d7ZaQ0GOM32LFZrFh7ztqI9YLUlMcC3TIj3jzgPtzU6UDcGXdLnOuTdV200V5P13R2q'
		: 'pk_test_51L893zDptqM2bn1xnKHngLDdoGw31lP180vXOJUziIF5JrIhjgBWvYxotYSIKtv3XYF9ry1fqp8fcKk5MOLvp3Kk00i1QBI3Qa'

export const TAX_PRODUCT = STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY ? 'prod_OvRnehcGgIjriC' : 'prod_OupNhkYfFDtkY4'

export const STRIPE_FREE_SHIPPING = STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY ? 'shr_1O7fCqDptqM2bn1xywaqU7GJ' : 'shr_1O5raKDptqM2bn1xaxlLJiCN'

export const AVALARA_CREDENTIALS =
	STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY
		? {
				username: 'alicia@eightsleep.com',
				password: '8Accounting2023!',
		  }
		: {
				username: 'nick.xitco@eightsleep.com',
				password: '9nwrCfuk$xc!mKVb7@g5yNglUFYTY#ixA',
		  }

export const AVALARA_COMPANY_CODE = STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY ? 'EIGHTSLEEP' : 'DEFAULT'

export type CouponMetadataType = 'line_item' | 'stacking' | 'global' | 'system' | 'single_item'

export type ProductCollection = 'cover' | 'mattress' | 'accessories' | 'upgrade' | 'ultra' | 'cover,mattress' | 'cover,ultra' | 'cover,mattress,subscription' | 'base' | 'subscription'

export interface ICouponMetadata {
	type: CouponMetadataType
	source: 'script' | 'verifypass'
	collections: ProductCollection
	discount_map?: string
	is_global_sale?: true
}

const loadENV = () => {
	dotenv.config({ path: '.env.local' })
	return process.env[STRIPE_KEY]
}

export const StripeManager = {
	stripe: new Stripe(STRIPE_ADMIN_KEY || loadENV(), { apiVersion: '2024-06-20' }),
}

export const AFFIRM_CA_PUBLIC_KEY = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_CA_PUBLIC_KEY : process.env.NEXT_PUBLIC_AFFIRM_CA_PUBLIC_KEY
export const AFFIRM_CA_URL = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_CA_URL : process.env.NEXT_PUBLIC_AFFIRM_CA_URL
export const AFFIRM_CA_JS = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_CA_JS : process.env.NEXT_PUBLIC_AFFIRM_CA_JS
export const AFFIRM_CA_PASSWORD = IS_AFFIRM_SANDBOX ? process.env.SANBOX_AFFIRM_CA_PASSWORD : process.env.AFFIRM_CA_PASSWORD

export const AFFIRM_US_PUBLIC_KEY = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_US_PUBLIC_KEY : process.env.NEXT_PUBLIC_AFFIRM_US_PUBLIC_KEY
export const AFFIRM_US_URL = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_US_URL : process.env.NEXT_PUBLIC_AFFIRM_US_URL
export const AFFIRM_US_JS = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_US_JS : process.env.NEXT_PUBLIC_AFFIRM_US_JS
