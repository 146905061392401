import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getSizeVisuals = (metric = false, isPod3: boolean): VisualProps => {
	if (!metric) {
		if (isPod3) {
			return getPod3SizeVisuals()
		}
		return getPod4SizeVisuals()
	}

	return getNonUSSizeVisuals()
}

export const getPod4SizeVisuals = (): VisualProps => {
	return {
		type: 'stack',
		associatedSelectorKeys: ['size'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod4_mattress_size_full.png',
					alt: 'Full Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod4_mattress_size_full_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'full',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod4_mattress_size_queen.png',
					alt: 'Queen Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod4_mattress_size_queen_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'queen',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_pod4_mattress_size_king_oct24.png',
					alt: 'King Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_pod4_mattress_size_king_mobile_oct24.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'king',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_pod4_mattress_size_caliking_oct24.png',
					alt: 'Cali King Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_pod4_mattress_size_caliking_mobile_oct24.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'caliking',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod4_mattress_size_generic.png',
					alt: 'Pod 4 Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod4_mattress_size_generic_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: '*',
					},
				],
			},
		],
	}
}

export const getPod3SizeVisuals = (): VisualProps => {
	return {
		type: 'stack',
		associatedSelectorKeys: ['size'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_full.png',
					alt: 'Full Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_full_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'full',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_queen.png',
					alt: 'Queen Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_queen_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'queen',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_king.png',
					alt: 'King Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_king_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'king',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_caliking.png',
					alt: 'Cali King Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_caliking_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: 'caliking',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_generic.png',
					alt: 'Pod 3 Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_mattress_size_generic_mobile.png',
				},
				associatedSelections: [
					{
						key: 'size',
						value: '*',
					},
				],
			},
		],
	}
}

export const getNonUSSizeVisuals = (): VisualProps => {
	return {
		type: 'stack',
		associatedSelectorKeys: ['size'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_international_mattress_size_generic.png',
					alt: 'Pod 3 Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod3_international_mattress_size_generic_mobile.png',
				},
				associatedSelections: [
					{
						key: 'pod-model',
						value: 'pod_3_cover_perfect',
					},
				],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod4_international_mattress_size_generic.png',
					alt: 'Pod 4 Size',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sizes_new/shop_pod4_international_mattress_size_generic_mobile.png',
				},
				associatedSelections: [
					{
						key: 'pod-model',
						value: '*',
					},
				],
			},
		],
	}
}
