import { FC, useEffect } from 'react'
import styles from './SideNav.module.scss'
import { SideNavProps } from './types'
import { Button } from 'components/Phantom/Button'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { findClosestSelector } from 'components/Phantom/_shop/utils'

export const SideNavConnected = observer(() => {
	const { shopStore } = useRootStore()

	const currentSelectors = shopStore.currentSelectors

	const findSelectedSlice = () => {
		const currentIndex = currentSelectors.findIndex((selector) => selector === shopStore.highlightedSelector)
		if (currentIndex === -1) {
			return 0
		}
		return currentIndex
	}

	useEffect(() => {
		const scrollHandler = () => {
			if (window.innerWidth < 1024) {
				return
			}

			shopStore.setHighlightedSelector(findClosestSelector(currentSelectors))
		}

		window.addEventListener('scroll', scrollHandler, { passive: true })
		scrollHandler()
		return () => {
			window.removeEventListener('scroll', scrollHandler)
		}
	}, [currentSelectors])

	return (
		<SideNav
			numSlices={currentSelectors.length}
			onClick={(i) => {
				const element = document.getElementById(`selector-${currentSelectors[i]}`)
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'center' })
				}
			}}
			selectedSlice={findSelectedSlice()}
		/>
	)
})

export const SideNav: FC<SideNavProps> = (props) => {
	const { numSlices, onClick, selectedSlice, slideIndicesToHide = [] } = props

	return (
		<ul
			className={styles.container}
			style={{
				gridTemplateRows: `repeat(${numSlices}, 1fr)`,
			}}
			id={'shop-side-nav'}
		>
			{Array.from({ length: numSlices }).map((_, i) => {
				if (slideIndicesToHide.includes(i)) {
					return null
				}
				return (
					<li
						key={i}
						className={styles.slice}
					>
						<Button.Empty
							id={`side-nav-button-${i}`}
							onClick={() => onClick(i)}
							className={styles.button}
						>
							<span
								style={{
									opacity: selectedSlice === i ? 1 : 0.25,
								}}
								className={styles.button_interior}
							/>
						</Button.Empty>
					</li>
				)
			})}
		</ul>
	)
}
