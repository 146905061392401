import styles from './Pod4ShopQualityPromises.module.scss'
import { VideoGif } from 'components/basic/VideoGif'

export const Pod4ShopQualityPromises = () => {
	return (
		<section className={`inner-paddings-y ${styles.root}`}>
			<div className={styles.header}>
				<h2>The Pod’s enduring quality promise </h2>
			</div>
			<div className={styles.slider}>
				{/* <Img
					src="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/IMG+9848(2).jpg"
					alt="Quality Promise "
					className={styles.desktop_only}
				/> */}
				<VideoGif
					src="https://d115sb6i1ixllw.cloudfront.net/assets/pod4_promises_no_sound.mp4"
					className={styles.desktop_only}
				/>

				<VideoGif
					className={styles.mobile_only}
					src="https://d115sb6i1ixllw.cloudfront.net/assets/pod4_promises_mob_no_sound.mp4 "
				/>
			</div>
			<div className={styles.content}>
				<div>
					<p>Over 200 tests guarantee its lasting performance</p>
					<p>
						Crafted with precision engineering and cutting-edge materials, Pod 4 was crafted to stand the test of time. Its design has undergone over 200 different tests that ensure durability,
						including simulating 10,000 use cycles.
					</p>
				</div>
				<div>
					<p>Sleep with confidence with our warranty </p>
					<p>
						Our dedication to quality ensures that each Pod comes with a warranty. Sleep soundly backed by our promise to maintain the highest standards of durability and support. Your comfort is
						secured by our commitment to excellence.
					</p>
				</div>
			</div>
		</section>
	)
}

export default Pod4ShopQualityPromises
