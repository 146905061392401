import { FC, useRef } from 'react'
import styles from './MiniShop.module.scss'
import { MiniShopProps } from './MiniShop.types'
import { Visuals, VisualsProps } from 'components/Phantom/_shop/Visuals'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import { ProductTitle } from 'components/Phantom/_shop/ProductTitle'
import { ProductTitleProps } from 'components/Phantom/_shop/ProductTitle/types'
import { Dropdown, DropdownOption } from 'components/Dropdown'
import { Button } from 'components/Phantom/Button'
import { PodModel } from 'shop/shopManager'
import { StandardCardProps } from 'components/Phantom/_shop/Selectors'
import { ModalConnected } from 'components/Phantom/_shop/Modals/Modal'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

export const MiniShopConnected = observer(() => {
	const { shopStore, priceStore } = useRootStore()

	const currentVisuals = shopStore.getCurrentVisuals()
	const currentSelections = shopStore.getCurrentSelections()

	const visualProps: VisualsProps = {
		visuals: currentVisuals,
		currentSelectorKey: 'pod-model',
		currentSelections: currentSelections,
	}

	const productTitleProps: ProductTitleProps = {
		title: shopStore.podData.name,
		price: shopStore.podVariant.prices.price,
		comparePrice: shopStore.podVariant.prices.comparePrice,
		financingProvider: priceStore.financingProvider,
		financingAmount: shopStore.selectedPodVariantFinancing,
		currency: priceStore.currency,
	}

	const modelSelected = currentSelections['pod-model'] as PodModel
	const sizeSelected = currentSelections['size'] as string

	const sizeProps = shopStore.getSelectorProps('size') as StandardCardProps

	const getDescription = () => {
		switch (modelSelected) {
			case 'pod_4':
				return 'The Pod 4 Cover can be added to any mattress, automatically regulating you and your partner’s body temperature to help you sleep longer and more deeply.'
			case 'pod_4_ultra':
				return 'The Pod 4 Ultra mitigates snoring through automated elevation, and allows for custom positioning for reading and relaxing. The Ultra contains the same temperature regulation features as the Pod 4, and can similarly fit any mattress and bed frame. '
			default:
				return 'The Pod Cover can be added to any mattress, automatically regulating you and your partner’s body temperature to help you sleep longer and more deeply.'
		}
	}

	return (
		<>
			<ModalConnected />
			<MiniShop
				visualProps={visualProps}
				productTitleProps={productTitleProps}
				sizeProps={sizeProps}
				description={getDescription()}
				selectModel={(model) => {
					shopStore.handleSelectionChange('pod-model', 'pod-model', model)
				}}
				modelSelected={modelSelected}
				selectSize={(size) => {
					shopStore.handleSelectionChange('size', 'size', size)
				}}
				sizeSelected={sizeSelected}
				sizeGuideClick={() => {
					shopStore.selectModal('size-modal')
				}}
			/>
		</>
	)
})

export const MiniShop: FC<MiniShopProps> = (props) => {
	const { visualProps, productTitleProps, description, selectModel, modelSelected, sizeProps, selectSize, sizeSelected, sizeGuideClick } = props
	const visualsRef = useRef<HTMLDivElement>(null)
	const shopContentRef = useRef<HTMLDivElement>(null)
	useAnimateInOnScroll(visualsRef)
	useAnimateInOnScroll(shopContentRef, { topAdjust: 50 })

	if (!visualProps || !productTitleProps || !sizeProps) return null

	const sizeDropdownOptions = sizeProps.data.options.map(
		(option) =>
			({
				key: option.key,
				labelMain: option.data.title,
				labelSub: option.data.subtitleTop,
			} as DropdownOption)
	)

	return (
		<section className={styles.container}>
			<div className={styles.wrapper}>
				<div
					className={styles.visuals}
					ref={visualsRef}
				>
					<Visuals
						{...visualProps}
						showPills={false}
						showBanner={false}
					/>
				</div>

				<div
					className={styles.shop_content}
					ref={shopContentRef}
				>
					<ProductTitle
						{...productTitleProps}
						hidePricing
						onlyDiscount
					/>
					<div className={styles.reviews}>
						<Stars />
						<Type.SmallPrint animateOnScroll={false}>{modelSelected === 'pod_4_ultra' ? `(4.6 stars) • 1,309 reviews` : `(4.3 stars) • 1,893 reviews`}</Type.SmallPrint>
					</div>
					<Type.Body2
						className={styles.description}
						animateOnScroll={false}
					>
						{description}
					</Type.Body2>

					{/* <div className={styles.model_selector}>
						<Type.Body1
							as={'h3'}
							animateOnScroll={false}
						>
							Model size
						</Type.Body1>
						<div className={styles.model_selector_buttons}>
							<Button.Empty
								className={cx(styles.model_selector_button, { [styles.model_selector_button_selected]: modelSelected === 'pod_4' })}
								id={'mini-shop-model-pod-4'}
								onClick={() => selectModel('pod_4')}
							>
								Pod 4
							</Button.Empty>
							<Button.Empty
								className={cx(styles.model_selector_button, { [styles.model_selector_button_selected]: modelSelected === 'pod_4_ultra' })}
								id={'mini-shop-model-pod-4-ultra'}
								onClick={() => selectModel('pod_4_ultra')}
							>
								Pod 4 Ultra
							</Button.Empty>
						</div>
					</div> */}

					<div className={styles.size_selector}>
						<Type.Body1
							as={'h3'}
							animateOnScroll={false}
						>
							Bed size
						</Type.Body1>
						<div className={styles.size_selector_buttons}>
							<Dropdown
								options={sizeDropdownOptions}
								name={'size'}
								onSelect={selectSize}
								placeholder={'Select a size'}
								selectedOptionKey={sizeSelected}
							/>
							<Button.Empty
								id={'mini-shop-size-guide'}
								className={styles.size_guide_button}
								onClick={sizeGuideClick}
							>
								Size guide
							</Button.Empty>
						</div>
					</div>
					<Button.Dark
						id={'mini-shop-add-to-cart'}
						href={'/product/pod-cover'}
						className={styles.add_to_cart_button}
					>
						Shop now
					</Button.Dark>
					<Type.SmallPrint
						className={styles.shipping_text}
						animateOnScroll={false}
					>
						Free shipping and returns
					</Type.SmallPrint>
				</div>
			</div>
		</section>
	)
}

export const Stars = () => (
	<svg
		viewBox="0 0 96 16"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M27.3.9c.3-.7,1.2-.7,1.5,0l1.6,3.9c.1.3.4.5.7.5l4.2.3c.7,0,1,.9.5,1.4l-3.2,2.7c-.2.2-.3.5-.3.8l1,4.1c.2.7-.6,1.2-1.2.9l-3.6-2.2c-.3-.2-.6-.2-.8,0l-3.6,2.2c-.6.4-1.4-.2-1.2-.9l1-4.1c0-.3,0-.6-.3-.8l-3.2-2.7c-.5-.5-.3-1.3.5-1.4l4.2-.3c.3,0,.6-.2.7-.5l1.6-3.9Z" />
		<path d="M47.3.9c.3-.7,1.2-.7,1.5,0l1.6,3.9c.1.3.4.5.7.5l4.2.3c.7,0,1,.9.5,1.4l-3.2,2.7c-.2.2-.3.5-.3.8l1,4.1c.2.7-.6,1.2-1.2.9l-3.6-2.2c-.3-.2-.6-.2-.8,0l-3.6,2.2c-.6.4-1.4-.2-1.2-.9l1-4.1c0-.3,0-.6-.3-.8l-3.2-2.7c-.5-.5-.3-1.3.5-1.4l4.2-.3c.3,0,.6-.2.7-.5l1.6-3.9Z" />
		<path
			className="cls-1"
			d="M95.9,6.1v.2c.1.2,0,.3,0,.4l-3.9,3.3,1.1,5c0,.2,0,.3-.2.4h-.2c0,.2-.2.2-.2.2,0,0-.1,0-.2,0l-4.4-2.7-4.3,2.7c0,0-.1,0-.2,0,0,0-.2,0-.2,0h-.2c-.1-.2-.2-.4-.2-.5l1.1-5-3.9-3.3c-.1-.1-.2-.3-.1-.4v-.2c.1-.2.3-.3.4-.3l5.1-.4,2-4.7c0-.2.2-.3.4-.3h.2c.2,0,.3,0,.4.3l2,4.7,5.1.4c.2,0,.3.1.4.3ZM91.3,13.2l-.9-3.7,2.9-2.5-3.8-.3-1.5-3.6v8.1l3.3,2Z"
		/>
		<path d="M75.7,7c.2-.2.3-.5.2-.8s-.4-.5-.7-.5l-4.2-.3c-.3,0-.6-.2-.7-.5l-1.6-3.9c-.2-.6-1.2-.6-1.5,0l-1.6,3.9c-.1.3-.4.5-.7.5l-4.2.3c-.3,0-.6.2-.7.5s0,.6.2.8l3.2,2.7c.2.2.3.5.3.8l-1,4.1c0,.3,0,.6.3.8.3.2.6.2.9,0l4.7-2.1,3.4,2c.1,0,.3.1.4.1s.3,0,.5-.2c.3-.2.4-.5.3-.8l-1-4.1c0-.3,0-.6.3-.8l3.2-2.7Z" />
		<path d="M7.3.9c.3-.7,1.2-.7,1.5,0l1.6,3.9c.1.3.4.5.7.5l4.2.3c.7,0,1,.9.5,1.4l-3.2,2.7c-.2.2-.3.5-.3.8l1,4.1c.2.7-.6,1.2-1.2.9l-3.6-2.2c-.3-.2-.6-.2-.8,0l-3.6,2.2c-.6.4-1.4-.2-1.2-.9l1-4.1c0-.3,0-.6-.3-.8L.3,7c-.5-.5-.3-1.3.5-1.4l4.2-.3c.3,0,.6-.2.7-.5l1.6-3.9Z" />
	</svg>
)
