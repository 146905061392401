import { RefObject, useEffect } from 'react'

const VERTICAL_THRESHOLD = 100
export const useSwipe = (ref: RefObject<HTMLElement>, callback: (direction: 'left' | 'right') => void, threshold = 150, allowedTime = 300) => {
	useEffect(() => {
		if (!ref.current) return null

		const element = ref.current
		let startX: number
		let startY: number
		let dist: number
		let elapsedTime: number
		let startTime: number

		const handleTouchStart = (e: TouchEvent) => {
			const touch = e.changedTouches[0]
			dist = 0
			startX = touch.pageX
			startY = touch.pageY
			startTime = new Date().getTime() // record time when finger first makes contact with surface
		}

		const handleTouchMove = (e: TouchEvent) => {
			// If x delta is greater than y delta, prevent default
			const touch = e.changedTouches[0]
			if (Math.abs(touch.pageX - startX) > Math.abs(touch.pageY - startY)) {
				e.preventDefault()
			}
		}

		const handleTouchEnd = (e: TouchEvent) => {
			const touch = e.changedTouches[0]

			dist = touch.pageX - startX // get total dist traveled by finger while in contact with surface

			elapsedTime = new Date().getTime() - startTime

			const swiped = elapsedTime <= allowedTime && Math.abs(dist) >= threshold && Math.abs(touch.pageY - startY) <= VERTICAL_THRESHOLD

			const swipeLeft = swiped && dist < 0
			const swipeRight = swiped && dist > 0

			if (swipeLeft) {
				callback('left')
			} else if (swipeRight) {
				callback('right')
			}
		}

		element.addEventListener('touchstart', handleTouchStart)
		element.addEventListener('touchmove', handleTouchMove)
		element.addEventListener('touchend', handleTouchEnd)

		return () => {
			element.removeEventListener('touchstart', handleTouchStart)
			element.removeEventListener('touchmove', handleTouchMove)
			element.removeEventListener('touchend', handleTouchEnd)
		}
	}, [])
}
