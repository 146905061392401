import { FC, useState } from 'react'
import styles from './BaseExplainer.module.scss'
import { BaseExplainerProps, Slide } from './BaseExplainer.types'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { getBaseExplainerSlides } from 'components/BaseExplainer/BaseExplainer.utils'
import { Button } from 'components/WebEv/Buttons'
import cx from 'classnames'
import { Img } from 'components/basic/Img'
import { VideoGif } from 'components/basic/VideoGif'
import { Icon } from 'components/Phantom/Icon'

export const BaseExplainerConnected = observer(() => {
	const { settingsStore } = useRootStore()

	return <BaseExplainer slides={getBaseExplainerSlides(settingsStore.currentRegion)} />
})

export const BaseExplainer: FC<BaseExplainerProps> = (props) => {
	const { slides } = props
	const [currentTab, setCurrentTab] = useState(0)

	return (
		<div className={styles.container}>
			<h2>All about the Pod 4 Ultra Base</h2>

			<ul className={styles.media_container}>
				{slides.map((slide, index) => (
					<li
						key={index}
						style={{ opacity: index === currentTab ? 1 : 0, pointerEvents: index === currentTab ? 'auto' : 'none', visibility: index === currentTab ? 'visible' : 'hidden' }}
					>
						<MediaContent {...slide} />
					</li>
				))}
			</ul>
			<ul className={styles.tabs}>
				{slides.map((slide, index) => (
					<li
						className={styles.tab_wrapper}
						key={index}
					>
						<Button.Empty
							id={`base-explainer-tab-${slide.title}`}
							className={cx(styles.tab, { [styles.tab_selected]: index === currentTab })}
							onClick={() => setCurrentTab(index)}
						>
							{slide.title}
						</Button.Empty>
					</li>
				))}
			</ul>
			<ul className={styles.lists}>
				{slides.map((slide, index) => (
					<li
						className={styles.list_wrapper}
						key={index}
						style={{ opacity: index === currentTab ? 1 : 0, pointerEvents: index === currentTab ? 'auto' : 'none', visibility: index === currentTab ? 'visible' : 'hidden' }}
					>
						{slide.lists.map((list, index) => (
							<div
								className={styles.list}
								key={index}
							>
								{list.title && <h3>{list.title}</h3>}
								<ul className={styles.inner_list}>
									{list.items.map((item, index) => (
										<li key={index}>
											<p dangerouslySetInnerHTML={{ __html: item }}></p>
										</li>
									))}
								</ul>
							</div>
						))}
					</li>
				))}
			</ul>
		</div>
	)
}

const MediaContent: FC<Slide> = (props) => {
	const { media, title, lists } = props

	const [currentTab, setCurrentTab] = useState(0)

	if (media.length === 0) return null
	if (media.length === 1) {
		if (media[0].type === 'image') {
			return (
				<Img
					src={media[0].data.src}
					alt={media[0].data.alt}
					dprHeight={1000}
					className={styles.media_img}
					objectFit={media[0].data.objectFit || 'cover'}
					sources={media[0].data.mobileSrc ? [{ src: media[0].data.mobileSrc, mediaQuery: '(max-width: 1023px)' }] : undefined}
				/>
			)
		} else if (media[0].type === 'video') {
			return (
				<VideoGif
					src={media[0].data.src}
					className={styles.media_video}
				/>
			)
		}
	}

	return (
		<>
			<ul className={styles.media_list}>
				{media.map((item, index) => (
					<li
						key={index}
						style={{ opacity: index === currentTab ? 1 : 0, pointerEvents: index === currentTab ? 'auto' : 'none', visibility: index === currentTab ? 'visible' : 'hidden' }}
						className={styles.media_item}
					>
						{item.data.caption && <p className={styles.media_caption}>{item.data.caption}</p>}
						{item.type === 'image' ? (
							<Img
								src={item.data.src}
								alt={item.data.alt}
								dprHeight={1000}
								className={styles.media_img}
								objectFit={item.data.objectFit || 'cover'}
								sources={item.data.mobileSrc ? [{ src: media[0].data.mobileSrc, mediaQuery: '(max-width: 1023px)' }] : undefined}
							/>
						) : (
							<VideoGif
								src={item.data.src}
								className={styles.media_video}
							/>
						)}
					</li>
				))}
			</ul>
			{currentTab !== media.length - 1 ? (
				<Button.Empty
					id={'base-explainer-tab-next'}
					className={styles.button_next}
					onClick={() => setCurrentTab((prev) => prev + 1)}
				>
					<Icon
						name={'ChevronRightDark'}
						color={'black'}
						size={24}
					/>
				</Button.Empty>
			) : null}

			{currentTab !== 0 ? (
				<Button.Empty
					id={'base-explainer-tab-prev'}
					className={styles.button_prev}
					onClick={() => setCurrentTab((prev) => prev - 1)}
				>
					<Icon
						name={'ChevronLeftDark'}
						color={'black'}
						size={32}
					/>
				</Button.Empty>
			) : null}

			<ul className={styles.dots}>
				{media.map((_, index) => (
					<li
						key={index}
						className={styles.dot}
						style={{
							opacity: index === currentTab ? 1 : 0.5,
						}}
					>
						<Button.Empty
							id={`base-explainer-dot-${index}`}
							onClick={() => setCurrentTab(index)}
							aria-label={'Go to slide ' + (index + 1)}
						/>
					</li>
				))}
			</ul>
		</>
	)
}
