import { forwardRef, VideoHTMLAttributes } from 'react'

interface SourceProps {
	src: string
	type: string
}

interface VideoGifProps extends VideoHTMLAttributes<HTMLVideoElement> {
	sources?: SourceProps[]
}

export const VideoGif = forwardRef<HTMLVideoElement, VideoGifProps>((props, ref) => {
	const { sources, ...videoProps } = props

	return (
		<video
			playsInline
			disablePictureInPicture
			disableRemotePlayback
			autoPlay
			loop
			muted
			ref={ref}
			{...videoProps}
		>
			{sources?.map((source, index) => (
				<source
					key={index}
					src={source.src}
					type={source.type}
				/>
			))}
		</video>
	)
})

VideoGif.displayName = 'VideoGif'
