import { FC } from 'react'
import styles from './ProductTitle.module.scss'
import { ProductTitleProps } from './types'
import { PricingInfo } from '../PricingInfo'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import { TruemedInfo } from 'components/TruemedInfo/TruemedInfo'
import { PriceManager } from 'prices'
import { FinancingInfo } from 'components/Financing/Financing'
import cx from 'classnames'
import { Visuals } from 'components/Phantom/_shop/Visuals'
import { PaymentOptionTabsConnected } from 'components/FinancingTabModal'
import { Type } from 'components/Type'
import { Stars } from 'pageCores/prospectingBJulia/localComponents/MiniShop'
import useABTestVariant from 'hooks/useABTestVariant'
import { shopCarouselAB } from 'ab_testing'

/**
 * Connected version of the `<ProductTitle/>` component. Automatically subscribes to the shop store.
 */
export const ProductTitleConnected = observer(() => {
	const { shopStore, priceStore } = useRootStore()
	const isShopCarouselAB = useABTestVariant(shopCarouselAB, 1)
	const selectors = shopStore.currentSelectors
	const selectorsProps = selectors.map((selectorKey) => shopStore.getSelectorProps(selectorKey)).filter((x) => !!x)
	const currentVisuals = shopStore.getCurrentVisuals(isShopCarouselAB)
	const currentSelections = shopStore.getCurrentSelections()

	const props: ProductTitleProps = {
		title: shopStore.podData.name,
		price: shopStore.podVariant.prices.price,
		comparePrice: shopStore.podVariant.prices.comparePrice,
		financingProvider: priceStore.financingProvider,
		financingAmount: shopStore.selectedPodVariantFinancing,
		currency: priceStore.currency,
	}

	return (
		<>
			<div className={styles.mobile_visual}>
				<Visuals
					visuals={currentVisuals}
					currentSelectorKey={selectorsProps[0].selectorKey}
					renderSingle={true}
					currentSelections={currentSelections}
				/>
			</div>

			<div className={styles.sticky_wrapper_mobile}>
				<PaymentOptionTabsConnected />
			</div>

			<ProductTitle
				{...props}
				hidePricing={true}
				as={'h1'}
				modelSelected={currentSelections['pod-model']}
			/>
		</>
	)
})

/**
 * First shop page component. Displays the product title, price, and financing information.
 *
 * **Usually connected to the RootStore using the `<ProductTitleConnected/>` component**
 */
export const ProductTitle: FC<ProductTitleProps> = (props) => {
	const { title, price, comparePrice, currency, financingAmount, hidePricing = false, onlyDiscount = false, as = 'h1', modelSelected } = props
	const isShopCarouselAB = useABTestVariant(shopCarouselAB, 1)
	return (
		<section className={cx(styles.container, 'connected-title')}>
			<Type.Headline2
				as={as}
				className={styles.product_title}
				animateOnScroll={false}
			>
				{title}
			</Type.Headline2>
			{isShopCarouselAB && (
				<div className={styles.reviews}>
					<Stars />
					<Type.SmallPrint animateOnScroll={false}>{modelSelected === 'pod_4_ultra' ? `(4.6 stars) • 1,309 reviews` : `(4.3 stars) • 1,893 reviews`}</Type.SmallPrint>
				</div>
			)}
			{!hidePricing && (
				<>
					<PricingInfo
						price={PriceManager.formatPriceToCurrencyNoDecimal(price / 100, currency)}
						comparePrice={PriceManager.formatPriceToCurrencyNoDecimal(comparePrice / 100, currency)}
						discountText={`${PriceManager.formatPriceToCurrencyNoDecimal((comparePrice - price) / 100, currency)} off`}
					/>
					<Type.SmallPrint className={styles.financing_info}>
						<FinancingInfo
							priceFormatted={financingAmount}
							className={styles.financing_info_inner}
						/>
					</Type.SmallPrint>
					<TruemedInfo className={styles.financing_info} />
				</>
			)}
			{onlyDiscount && (
				<PricingInfo
					price={''}
					comparePrice={''}
					discountText={`${PriceManager.formatPriceToCurrencyNoDecimal((comparePrice - price) / 100, currency)} off`}
				/>
			)}
		</section>
	)
}
