import { ScreenBreakPoint } from '../constants'
import { Palette2019, Palette } from '../constants'

const theme = {
	name: 'Eight',

	// `${ScreenBreakPoint.sd}px`,
	breakpoints: [`${ScreenBreakPoint.sm}px`, `${ScreenBreakPoint.md}px`, `${ScreenBreakPoint.lg}px`, `${ScreenBreakPoint.xl}px`],

	colors: {
		// Common
		Black: `${Palette.Primary.Sky}`,
		White: `${Palette.Primary.Luna}`,
		bgLight: `${Palette.Extended.Neutrals.N07}`,

		// Primary
		Sky: `${Palette.Primary.Sky}`,
		Luna: `${Palette.Primary.Luna}`,
		MilkyWay: `${Palette.Primary.MilkyWay}`,
		SpaceDust: `${Palette.Primary.SpaceDust}`,
		DarkMatter: `${Palette.Extended.Purples.P02}`,

		// Secondary
		Invigoration: `${Palette.Secondary.Invigoration}`,
		Tahoe: `${Palette.Secondary.Tahoe}`,
		Charge: `${Palette.Secondary.Charge}`,
		OJ: `${Palette.Secondary.OJ}`,
		Vigor: `${Palette.Secondary.Vigor}`,
		LiquidDream: `${Palette.Secondary.LiquidDream}`,

		// Purples
		P02: `${Palette.Extended.Purples.P02}`,

		DarkGray: `${Palette2019.Primary.DarkGray}`,
		Blue: `${Palette2019.Primary.Blue}`,
		MediumGray: `${Palette2019.Secondary.MediumGray}`,
		LightGray: `${Palette2019.Secondary.LightGray}`,
		LighterGray: `${Palette2019.Secondary.LighterGray}`,
	},

	colors2019: {
		// Primary
		DarkGray: `${Palette2019.Primary.DarkGray}`,
		Blue: `${Palette2019.Primary.Blue}`,

		// Secondary
		MediumGray: `${Palette2019.Secondary.MediumGray}`,
		LightGray: `${Palette2019.Secondary.LightGray}`,
		LighterGray: `${Palette2019.Secondary.LighterGray}`,
	},

	fonts: {},

	fontSizes: ['1rem', '1.3rem', '1.5rem', '2rem', '3rem', '4rem', '6rem'],

	lineHeights: ['1.9rem', '1.9rem'],

	letterSpacings: ['0.08rem', '0.1rem'],

	space: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],

	Button: {
		backgroundColor: `${Palette.Primary.Sky}`,
		backgroundColorMobile: '#366eff',
		border: '2px solid',
		borderColor: `${Palette.Primary.Sky}`,
		borderRadius: '1px',
		boxSizing: 'border-box',
		color: 'Luna',
		cursor: 'pointer',
		fontFamily: 'var(--neue-montreal), sans-serif',
		fontSize: '1.2rem',
		fontWeight: 'regular',
		padding: '1rem 3rem',
		transition: 'all 0.2s',

		'&:hover': {
			opacity: 1,
			backgroundColor: `${Palette.Extended.Purples.P02}`,
			borderColor: `${Palette.Extended.Purples.P02}`,
		},
	},

	buttons: {
		primary: {
			color: '#000',
			backgroundColor: `${Palette.Primary.SpaceDust} !important`,
			borderColor: `${Palette.Primary.SpaceDust} !important`,
		},

		secondary: {},

		outline: {
			backgroundColor: 'transparent !important',
			color: `${Palette.Primary.Sky}`,
			border: '2px solid',
			borderColor: `${Palette.Primary.Sky}`,

			'&:hover': {
				color: `${Palette.Extended.Purples.P02}`,
			},
		},

		formInline: {},
	},
}

export default theme
