import React, { FC } from 'react'
import styles from './ProductModelSelect.module.scss'
import { TooltipRebuilt } from 'components/TooltipRebuilt'
import { ProductSelectFields } from 'products/types'
import cx from 'classnames'

interface ProductPropsCalculated extends ProductSelectFields {
	isChecked: boolean
	index: number
	onCheck: (index: number) => void
	new?: boolean
}

export interface ModelSelectProps {
	labels: ProductSelectFields[]
	legendLabel: string
	className?: string
	selectedModel: number
	onCheck: (index: number) => void
	new?: boolean
}

export const ProductModelSelect: FC<ModelSelectProps> = (props) => {
	const numItems = props.labels.length

	const newStyles = {
		gridTemplateRows: numItems <= 3 ? 'minmax(0, 1fr)' : 'unset',
		gridTemplateColumns: numItems <= 3 ? `repeat(${numItems}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))',
	}

	return (
		<form>
			<fieldset
				className={`${props.new ? styles.fieldset : styles.fieldset_old} ${props.className}`}
				tabIndex={0}
				style={props.new ? newStyles : {}}
			>
				<legend className="vh">{props.legendLabel}</legend>
				{props.labels.map((product, index) => (
					<ModelInput
						{...product}
						isChecked={props.selectedModel === index}
						index={index}
						key={index}
						onCheck={props.onCheck}
						new={props.new}
					/>
				))}
			</fieldset>
		</form>
	)
}

const ModelInput: FC<ProductPropsCalculated> = (props) => {
	function handleChange() {
		props.onCheck(props.index)
	}

	if (props.id == null) {
		return null
	}

	return (
		<>
			<input
				className="vh"
				type="radio"
				id={props.id}
				name="productModelInput"
				checked={props.isChecked}
				onChange={handleChange}
			/>
			<label
				htmlFor={props.id}
				className={props.new ? styles.label : styles.label_old}
			>
				{props.tooltip ? <TooltipRebuilt {...props.tooltip} /> : null}
				{props.badgeText ? <span className={props.new ? styles.badge : 'badge_v2'}>{props.badgeText}</span> : null}
				<span className={`title ${props.new ? styles.title : styles.title_old}`}>
					<span dangerouslySetInnerHTML={{ __html: props.title }}></span>
					{props.titleBadge ? <span className={cx('badge_v2', styles.bfcm_badge)}>{props.titleBadge}</span> : null}
				</span>
				{props.subtitle != null ? (
					<span className={`${styles.subtitle}`}>
						{props.subtitle}
						{props.subtitle2 ? <span className={styles.subtitle2}>{props.subtitle2}</span> : <></>}
					</span>
				) : null}
				{props.pricingInfo && (
					<span className={styles.pricing_info}>
						<span>{props.pricingInfo.price}</span>
						<del>{props.pricingInfo.strikePrice}</del>
						<em>{props.pricingInfo.discountTag}</em>
					</span>
				)}
				{props.comparePrice && props.comparePrice.trim() !== props.price.trim() ? <span className={`${styles.compare_price}`}>{props.comparePrice}</span> : null}
				{props.price ? <span className={styles.price_label}>{props.price}</span> : null}
				<span
					className={props.new ? styles.description : styles.description_old}
					dangerouslySetInnerHTML={{ __html: props.description }}
				/>
			</label>
		</>
	)
}
