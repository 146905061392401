import styles from './Pod4ShopComparison.module.scss'
import { Img } from 'components/basic/Img'
import { Button } from 'components/Phantom/Button'
import cx from 'classnames'
import { Icon } from 'components/Phantom/Icon'
import { inject, observer } from 'mobx-react'
import { PropsWithInjectedRootStore } from 'constants/types'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { featureIcons, getPod4FeatureMatrix } from 'constants/pod4-data'
import SlideDown from 'components/SlideDown'
import useIsMobile from 'hooks/useIsMobile'
import { FullScreenModal } from 'components/Phantom/_sections/ThisIsPod4Ultra'
import { Pod4ComparisonTable } from 'components/Pod4Comparison'
import { useState } from 'react'
import { FinancingInfo } from 'components/Financing/Financing'

interface PodData {
	name: string
	subheader: string
	img: string
	priceOld: string
	priceNew: string
	priceFinancing: string
	tag?: string
}

export interface Pod4ShopComparisonProps extends PropsWithInjectedRootStore {
	ctaHref?: string
	showTruemed?: boolean
}

export const getPods = () => {
	const pods: PodData[] = [
		{
			name: 'Pod 3',
			subheader: 'Includes Pod 3 Cover and Hub',
			img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/pod4-compare-pod3.png',
			priceOld: 'pod3ComparePriceFormatted',
			priceNew: 'pod3PriceFormatted',
			priceFinancing: 'pod3MonthlyPriceFormatted',
		},
		{
			name: 'Pod 4',
			subheader: 'Includes Pod 4 Cover and Hub',
			img: 'https://eightsleep.imgix.net/assets/podcompareleft_2204.png',
			tag: 'New',
			priceOld: 'pod4ComparePriceFormatted',
			priceNew: 'pod4PriceFormatted',
			priceFinancing: 'pod4MonthlyPriceFormatted',
		},
		{
			name: 'Pod 4 Ultra',
			subheader: 'Includes Pod 4 Cover, Hub, and adjustable Base',
			img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/pod4-compare-pod4ultra.png',
			tag: 'New',
			priceOld: 'pod4UltraComparePriceFormatted',
			priceNew: 'pod4UltraPriceFormatted',
			priceFinancing: 'pod4UltraMonthlyPriceFormatted',
		},
	]

	return pods
}

const Pod4ShopComparisonComponent = (props: Pod4ShopComparisonProps) => {
	const href = props.ctaHref || '/product/pod-cover/'
	const ctaLabel = 'Shop now'
	const metric = props.rootStore.settingsStore.metricRegion
	const isMobile = useIsMobile()
	const { priceStore, settingsStore } = props.rootStore
	const [compareIsVisible, setCompareIsVisible] = useState(false)

	const button = (
		<Button.Blue
			href={href.startsWith('#') ? undefined : href}
			className={styles.ctaButton}
			id={'pod4-compare-shop-now'}
		>
			<span className={styles.ctaLabel}> {ctaLabel}</span>
		</Button.Blue>
	)
	const ctaButton = href.startsWith('#') ? <AnchorLink href={href}>{button}</AnchorLink> : button
	const showPod3 = props.rootStore.settingsStore.settings.promoCode !== 'members'

	return (
		<section className={`inner-paddings-y ${styles.root}`}>
			<div className={styles.eyebrow}>COMPARE PODS</div>
			<div className={styles.header}>Which Pod is right for you?</div>
			<div
				className={styles.big_table}
				style={{ gridTemplateColumns: showPod3 ? '' : '1fr 1fr' }}
			>
				{getPods().map((pod, i) => (
					<div
						key={`features-table-${i}`}
						style={{ display: i === 0 && !showPod3 ? 'none' : '' }}
					>
						{pod.tag && <div className={styles.podEyebrow}>{pod.tag}</div>}
						<Img
							src={pod.img}
							alt={pod.name}
							className={styles.podImage}
						/>
						<div className={styles.podTitle}>{pod.name}</div>
						<div className={cx(styles.podSubheader)}>{pod.subheader}</div>
						<div className={styles.podInfoPrice}>
							<div className={styles.podNewPrice}>{priceStore[pod.priceNew]}</div>
							<div className={styles.strikethrough}>{priceStore[pod.priceOld]}</div>
						</div>
						<div className={styles.podFinancing}>
							<FinancingInfo
								customLogoClass={styles.podFinancingSvg}
								priceFormatted={priceStore[pod.priceFinancing]}
								price={priceStore[pod.priceFinancing]}
							/>
							{props.showTruemed && (
								<div className={styles.cta_footer}>
									HSA/FSA-eligible with{' '}
									<Img
										className={styles.truemed_logo_small}
										src={'https://d115sb6i1ixllw.cloudfront.net/assets/trueMed.svg'}
										alt={''}
									/>
								</div>
							)}
						</div>
						<div className={styles.podInfoCta}>{ctaButton}</div>
						<div className={styles.separator}></div>
						{getPod4FeatureMatrix(metric)
							.filter((feature) => !!feature.values[i])
							.map((feature) => (
								<div key={`feature-1-${feature.title}`}>
									<SlideDown
										top={
											<div className={styles.sliderFeature}>
												<Icon
													size={isMobile ? '16' : '24'}
													color={featureIcons[feature.title].color || 'black'}
													name={featureIcons[feature.title].name}
												/>
												{feature.title}
											</div>
										}
										key={i}
										className={styles.sliderFeatureContainer}
										inverted={true}
									>
										<span className={styles.sliderDescription}>{feature.description}</span>
									</SlideDown>
									{/* <p className={styles.feature_title}>{feature.title}</p>
										<p className={styles.feature_description}>{feature.description}</p> */}
								</div>
							))}
					</div>
				))}
			</div>
			<Button.Plus
				onClick={() => setCompareIsVisible(true)}
				id="compare-pods"
				className={styles.compareButton}
			>
				Compare to previous generations
			</Button.Plus>
			<FullScreenModal
				closeAction={() => {
					setCompareIsVisible(false)
				}}
				visible={compareIsVisible}
			>
				<div className={styles.fullScreenContent}>
					<Pod4ComparisonTable
						metric={settingsStore.metricRegion}
						showPod3={settingsStore.settings.promoCode !== 'members'}
					/>
				</div>
			</FullScreenModal>
		</section>
	)
}

export const Pod4ShopComparison = inject('rootStore')(observer(Pod4ShopComparisonComponent))
export default Pod4ShopComparison
