import { CheckboxSelectionItemProps } from 'components/Phantom/_shop/CheckboxSelection'
import { PricingData } from 'shop/Shop.types'

export const getMattressCheckboxProps = (mattressData: { numLayers: number; pricingData: PricingData }, showModal = false): CheckboxSelectionItemProps => {
	const { numLayers, pricingData } = mattressData
	return {
		selectionKey: 'eight-mattress',
		trueValue: 'yes_mattress',
		falseValue: 'none',
		title: `${numLayers}-layer Mattress`,
		description: 'Achieve the perfect mix of temperature control and comfort',
		icons: [
			{
				icon: 'ComfortLight',
				text: 'Medium firmness with spinal support',
			},
			{
				icon: 'BedLight',
				text: '11” mattress height',
			},
			{
				icon: 'VibrationLight',
				text: 'Pressure relieving materials',
			},
			{
				icon: 'WarmLight',
				text: 'Excellent airflow for cooler sleep',
			},
		],
		pricingData,

		modal: showModal && {
			modalKey: 'mattress-modal',
			text: 'Why add a mattress?',
		},
	}
}
