import { Source } from 'components/basic/Img'
import { ReactNode } from 'react'

export interface RouterQuery {
	handle: string
	defaultVariant: number
}

export interface RouterObj {
	pathname: string
	query: RouterQuery
	as: string
}

export interface ButtonLink {
	toObj?: RouterObj
	to?: string
}

export interface PromoMessage {
	message: string
	link: ButtonLink | null
	showTimer?: boolean
}

export interface PromoBar {
	[path: string]: PromoMessage | undefined
}

export enum VanityHeroComponent {
	DEFAULT = 'default',
	MORNING_BREW = 'morning_brew',
	TIM_FERRISS = 'tim_ferriss',
	FULL_BACKGROUND = 'full_background',
}

export interface HeroSettings {
	title: string
	description?: string[]
	sources?: Source[]
	component?: VanityHeroComponent
	heading?: string
	bgImage?: string
}

export interface FooterSettings {
	title: string
	sources?: Source[]
	paragraphs?: string[]
	ctaButtonLabel?: string
	isDark?: boolean
	footerClass?: string
}

export interface EmailSettings {
	title: string
	imageSrc: string
	imageAlt: string
	subtitle?: string
	description?: string
	amount?: string
}

export interface KlaviyoProperties {
	title: string
	image: string
	name: string
	discount: string
	mattressDiscount?: string
	link: string
}

export interface HomeSettings {
	heroTitle: string
	heroSubtitle: string[]
	heroButton: string | undefined
	heroButtonLinkDestination: string | undefined
}

export interface VanityData {
	utm_source: string
	utm_medium: string
	saleName: string
	savingsTag: string
	saleShopSavingsTag?: string
	promoBar: PromoBar
	promoCode: string
	heroSettings?: HeroSettings
	emailCapSettings?: EmailSettings
	preFooterSettings?: FooterSettings
	klaviyoProperties?: KlaviyoProperties
	page?: string
	holiday_promo?: boolean
	partnerName: string
	ecapImg?: string
	phantomImage?: string
}

export interface SiteSettings extends Partial<VanityData> {
	homeSettings?: HomeSettings

	cartTimer?: string
	cartLegalese: string
	checkoutLegalese: string
	cartTimerSubtitle?: string | undefined

	startDate: string // use UTC time here
	endDate: string // use UTC time here

	activeTimer: boolean

	homeHeroTitle: string
	homeHeroSubtitle: string
	homeHeroButton: string
	bedPerfectedText: string
	shopCardHeading: ReactNode
	shopCardTag?: string
	stickyCardTag?: string
	stickyCardTagMobile?: string
	pod3herotitle: string
	pod3herosubtitle: string
	coverlpherotitle: string
	coverlpherosubtitle: string
	coverLPButton: string
	compareHeading: string
	mattresslpherotitle: string
	mattresslpherosubtitle: string
	mattressLPButton: string
	comparepagetitle: string
	memberaccessoriestitle: string
	memberaccessoriestagtitle: string
	memberaccessoriessubtitle: string
	stickybartext: string
	hooxsubheading: string
	hooxHeading: string
	podProUpgradeShopPromoPill: string
	podProUpgradeAccessoryCardSaveText: string
	appPageSuperheading: string
	appPageHeading: string
}
