import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getAutopilotVisuals = (isShopCarouselAB: boolean): VisualProps => {
	return isShopCarouselAB
		? {
				type: 'new_carousel',
				associatedSelectorKeys: ['auto-pilot'],
				data: [
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-autopilot-slide-phone-file.png',
							alt: 'App showing autopilot',
						},
						associatedSelections: [],
						pills: [
							{
								icon: 'CoolLight',
								text: 'Sleep and health reports',
							},
							{
								icon: 'BedLight',
								text: 'Automatic temperature adjustments',
							},
							{
								icon: 'TwoUsersLight',
								text: 'One plan for you and your partner',
							},
						],
					},
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Autopilot-Slide2-345.png',
							alt: 'Autopilot explanation slide 2',
							doNotCompress: true,
							dprHeight: 2000,
							objectFit: 'cover',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Autopilot-Slide3-345.png',
							alt: 'Autopilot explanation slide 3',
							objectFit: 'cover',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Autopilot-Slide4-345.png',
							alt: 'Autopilot explanation slide 4',
							objectFit: 'cover',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/new-autopilot-slide-5-latest.png',
							alt: 'Autopilot explanation slide 5',
							objectFit: 'cover',
						},
						associatedSelections: [],
					},
				],
		  }
		: {
				type: 'new_carousel',
				associatedSelectorKeys: ['auto-pilot'],
				data: [
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilot_77843331.png',
							alt: 'App showing autopilot',
						},
						associatedSelections: [],
						pills: [
							{
								icon: 'CoolLight',
								text: 'Sleep and health reports',
							},
							{
								icon: 'BedLight',
								text: 'Automatic temperature adjustments',
							},
							{
								icon: 'TwoUsersLight',
								text: 'One plan for you and your partner',
							},
						],
					},
				],
		  }
}
