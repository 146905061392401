import { forwardRef, useImperativeHandle, useRef } from 'react'
import styles from './Type.module.scss'
import { TypeComponent, TypeProps } from './Type.types'
import cx from 'classnames'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

/**
 * The Type component is a reusable component for rendering headings and paragraphs.
 */
export const Type = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => {
	const { as: Component = 'p', children, className, animateOnScroll = true, animationSettings, ...rest } = props

	const innerRef = useRef<HTMLHeadingElement | HTMLParagraphElement>(null)

	useImperativeHandle(ref, () => innerRef.current)
	useAnimateInOnScroll(innerRef, { ...animationSettings, disable: !animateOnScroll })

	if (!children) return null

	const DEBUG = false

	return (
		<Component
			ref={innerRef}
			className={cx('type_base', className, { [styles.debug]: DEBUG }, { type_animated: animateOnScroll })}
			data-debug={className.split('_')[1]}
			{...rest}
		>
			{children}
		</Component>
	)
}) as unknown as TypeComponent // forwardRef upsets the Typescript linter, so we have to manually cast it.

/******************************************************************************
 * Subcomponents
 ******************************************************************************/

const MegaHeadline = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		as={'h1'}
		{...props}
		ref={ref}
		className={cx(styles.megaHeadline, props.className)}
	/>
))

const Headline1 = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		as={'h1'}
		{...props}
		ref={ref}
		className={cx(styles.headline1, props.className)}
	/>
))

const Headline2 = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		as={'h2'}
		{...props}
		ref={ref}
		className={cx(styles.headline2, props.className)}
	/>
))

const Headline3 = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		as={'h3'}
		{...props}
		ref={ref}
		className={cx(styles.headline3, props.className)}
	/>
))

const Headline4 = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		as={'h4'}
		{...props}
		ref={ref}
		className={cx(styles.headline4, props.className)}
	/>
))

const Headline5 = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		as={'h5'}
		{...props}
		ref={ref}
		className={cx(styles.headline5, props.className)}
	/>
))

const Headchapter = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		{...props}
		ref={ref}
		className={cx(styles.headchapter, props.className)}
	/>
))

const Body1 = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		{...props}
		ref={ref}
		className={cx(styles.body1, props.className)}
	/>
))

const Body2 = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		{...props}
		ref={ref}
		className={cx(styles.body2, props.className)}
	/>
))

const SmallPrint = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		{...props}
		ref={ref}
		className={cx(styles.smallPrint, props.className)}
	/>
))

const Eyebrow = forwardRef<HTMLHeadingElement | HTMLParagraphElement, TypeProps>((props, ref) => (
	<Type
		{...props}
		ref={ref}
		className={cx(styles.eyebrow, props.className)}
	/>
))

Type.MegaHeadline = MegaHeadline
Type.Headline1 = Headline1
Type.Headline2 = Headline2
Type.Headline3 = Headline3
Type.Headline4 = Headline4
Type.Headline5 = Headline5
Type.Headchapter = Headchapter
Type.Body1 = Body1
Type.Body2 = Body2
Type.SmallPrint = SmallPrint
Type.Eyebrow = Eyebrow
