import { StickyProps } from 'components/Phantom/_shop/Sticky/types'
import { SelectionSummaryProps } from 'components/Phantom/_shop/SelectionSummary/types'
import { SelectionProps } from 'components/Phantom/_shop/Selection/types'
import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'
import { CarouselProps } from 'components/Phantom/_shop/Carousel/types'
import { Img } from 'components/basic/Img'
import { SelectionModalButtonProps } from 'components/Phantom/_shop/SelectionModalButton/types'
import { ProductTitleProps } from 'components/Phantom/_shop/ProductTitle/types'
import { PricingInfoProps } from 'components/Phantom/_shop/PricingInfo/types'

/**
 * This file houses default props for testing the Shop page
 */

export const StickyDefaults: StickyProps = {
	title: 'Pod 4 Ultra',
	benefits: [
		{
			icon: 'ShieldCheckLight',
			text: '5-year warranty',
		},
		{
			icon: 'MoonLight',
			text: '30-night risk-free trial',
		},
		{
			icon: 'EightBoxLight',
			text: 'Free returns',
		},
		{
			icon: 'DeliveryLight',
			text: 'Free shipping',
		},
	],
	price: '$2,195',
	strikePrice: '$2,295',
	alternatePriceDesktop: '$61/mo*',
	alternatePriceMobile: 'or as low as $60/mo with',
	financingProvider: 'affirm',
	buttonText: 'Checkout',
	discountText: '$100 off',
	open: true,
}

export const SelectionSummaryDefaults: SelectionSummaryProps = {
	price: '$4,295',
	alternatePrice: 'or as low as $87/mo with',
	financingProvider: 'affirm',
	orderItems: 'Pod 4 Ultra + Leg kit + Bundle + Eight Sleep Mattress',
	buttonText: 'Checkout',
	shippingText: 'Ships Jan 25 - Feb 1',
	benefits: [
		{
			icon: 'ShieldCheckLight',
			text: '5-year warranty',
		},
		{
			icon: 'MoonLight',
			text: '30-night risk-free trial',
		},
		{
			icon: 'EightBoxLight',
			text: 'Free returns',
		},
		{
			icon: 'DeliveryLight',
			text: 'Free shipping',
		},
	],
}
export const CardSelectionItemDefaults: CardSelectionItemProps = {
	data: { title: 'Add leg kit', subtitleMedium: 'Replace your current bed frame with the Base' },
	type: 'standard',
	highlightColor: '#0038FF',
	highlightTag: 'New',
	selected: false,
	key: '',
}

export const SelectionDefaults: SelectionProps = {
	title: 'Model',
	subtitle: 'Select your model',
	pricingInfo: {
		price: '$2,195',
		strikePrice: '$2,295',
		discountText: '$100 off',
	},

	selectionType: 'card-selection',
	selectionProps: {
		onChange: (value: number) => console.log(value),
		options: [
			{
				...CardSelectionItemDefaults,
				key: 'model1',
			},
			{
				...CardSelectionItemDefaults,
				key: 'model2',
			},
			{
				...CardSelectionItemDefaults,
				key: 'model3',
			},
		],
	},
	modalButtons: [
		{
			modalKey: 'example',
			text: 'Need help choosing',
		},
	],
	modalClick: (key: string) => console.log(key),
	key: '',
}

export const ProductTitleDefaults: ProductTitleProps = {
	title: 'Pod 4 Ultra',
	price: 2195,
	strikePrice: 2295,
	discount: { type: 'amount', value: 100 },
	currency: 'USD',
	financingProvider: 'affirm',
}

export const PricingInfoDefaults: PricingInfoProps = {
	price: '$2,195',
	strikePrice: '$2,295',
	discountText: '$100 off',
}

export const SelectionModalButtonDefaults: SelectionModalButtonProps = {
	modalKey: 'example',
	text: 'Need help choosing',
}

export const CarouselDefaults: CarouselProps = {
	items: [
		<Img
			src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_test/1b01c4945936d42d2ce1bf3d66398c2e.png'}
			alt={''}
		/>,
		<Img
			src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_test/63c4941b705ae2986a1833fb8dcbe4c4.png'}
			alt={''}
		/>,
		<Img
			src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop_test/d3509499fe3ae2e8b314c42361398c6b.png'}
			alt={''}
		/>,
	],
}
