import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getLegKitVisuals = (metric = false): VisualProps => {
	if (metric) {
		return {
			type: 'image',
			associatedSelectorKeys: ['leg-kit'],
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Leg+Kit+-+Metric+System+-+Desktop.png',
				alt: 'Leg Ket display',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Leg+Kit+-+Metric+System+-+Mobile.png',
			},
		}
	}

	return {
		type: 'image',
		associatedSelectorKeys: ['leg-kit'],
		data: {
			src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Leg+Kit+-+Imperial+System+-+Desktop.png',
			alt: 'Leg Ket display',
			mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Leg+Kit+-+Imperial+System+-+Mobile.png',
		},
	}
}
