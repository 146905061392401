import { FC } from 'react'
import styles from './CheckboxSelection.module.scss'
import { CheckboxSelectionItemProps, CheckboxSelectionProps } from './CheckboxSelection.types'
import * as React from 'react'
import { Icon } from 'components/Phantom/Icon'
import { PricingInfo } from 'components/Phantom/_shop/PricingInfo'
import { Button } from 'components/Phantom/Button'

export const CheckboxSelection: FC<CheckboxSelectionProps> = (props) => {
	const { onChange, options, error, currentSelections, onModalTrigger } = props

	if (!options || options.length === 0) return null

	return (
		<div className={styles.container}>
			{options.map((option) => {
				const trueValue = option.trueValue || 'true'
				const falseValue = option.falseValue || 'false'
				return (
					<label
						className={styles.option}
						key={option.selectionKey}
					>
						<input
							type={'checkbox'}
							checked={currentSelections[option.selectionKey] === trueValue}
							onChange={(e) => {
								if (e.target.checked) {
									onChange(option.selectionKey, trueValue)
								} else {
									onChange(option.selectionKey, falseValue)
								}
							}}
							className={styles.checkbox}
						/>

						<span>
							<span className={styles.title}>{option.title}</span>
							<span className={styles.description}>{option.description}</span>

							<Icons {...option} />

							<PricingInfo
								price={option.pricingData.price}
								comparePrice={option.pricingData.strikePrice}
								discountText={option.pricingData.discountText}
							/>

							{option.modal && (
								<Button.Empty
									id={`${option.modal.modalKey}-modal-trigger`}
									onClick={() => onModalTrigger(option.modal.modalKey)}
									className={styles.modal_trigger}
								>
									{option.modal.text}
								</Button.Empty>
							)}
						</span>
					</label>
				)
			})}
		</div>
	)
}

const Icons: FC<CheckboxSelectionItemProps> = (props) => {
	const { icons } = props

	if (!icons || icons.length === 0) return null

	return (
		<ul className={styles.icon_list}>
			{icons.map((icon, i) => (
				<li
					className={styles.icon_row}
					key={`upsell-icon-${i}`}
				>
					<Icon
						name={icon.icon}
						color={'#a0a0a0'}
						size={24}
					/>
					<p>{icon.text}</p>
				</li>
			))}
		</ul>
	)
}
