import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'
import { PaymentOption } from 'shop/Shop.types'
import { ClientOnly } from 'components/ClientOnly'
import Script from 'next/script'
import { AffirmLogo } from 'components/_const_assets/affirmLogo'
import * as React from 'react'

export const Pod3: CardSelectionItemProps = {
	key: 'pod_3_cover_perfect',
	type: 'standard',
	data: {
		title: 'Pod 3',
		subtitleTop: 'Cover + Hub',
		subtitleSmallest: 'Includes the below:',
		checklist: ['Previous generation technology'],
	},
}

export const Pod4: CardSelectionItemProps = {
	key: 'pod_4',
	type: 'standard',
	data: {
		title: 'Pod 4',
		badge: 'New',
		subtitleTop: 'Cover + Hub',
		subtitleSmallest: 'Includes the below:',
		checklist: ['2x more cooling power', 'Silent performance', 'Enhanced comfort', 'Tap to control', 'Snoring detection'],
	},
}

export const Pod4Ultra: CardSelectionItemProps = {
	key: 'pod_4_ultra',
	type: 'standard',
	data: {
		title: 'Pod 4 Ultra',
		badge: 'New',
		subtitleTop: 'Cover + Hub + Base',
		subtitleSmallest: 'Everything in Pod 4, plus:',
		checklist: ['Sleeping, reading, and custom positioning', 'Snoring mitigation'],
	},
}

export const getModelOptions = (): CardSelectionItemProps[] => {
	return [Pod3, Pod4, Pod4Ultra]
}

export const getModelDescription = (paymentOption: PaymentOption, financingPriceString: string) => {
	switch (paymentOption) {
		case 'affirm':
			return (
				<>
					*Buy now and pay over time with <AffirmLogo />. Starts as low as {financingPriceString}.{' '}
					<button onClick={() => document.getElementById('affirm-info-cta-button')?.click()}>Learn more.</button>
				</>
			)
		case 'klarna':
			return 'Buy now and pay over 3 payments'
		case 'truemed':
			return (
				<>
					{/**Save an average of 33% when you pay with HSA/FSA.{' '}*/}
					{/*<button*/}
					{/*	onClick={() => {*/}
					{/*		document.getElementById('truemed-instructions')?.click()*/}
					{/*	}}*/}
					{/*>*/}
					{/*	Learn more.*/}
					{/*</button>*/}
					<ClientOnly>
						<p
							id="truemed-instructions"
							icon-height="11"
							style={{ margin: '0', lineHeight: '1' }}
						></p>
						<Script
							src="https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/eight-sleep-pdp-widget.min.js"
							strategy="afterInteractive"
						></Script>
					</ClientOnly>
				</>
			)
		case 'cash':
		default:
			return null
	}
}
