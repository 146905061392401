import { SiteSettings } from 'config/types'
import { FullHeroWithQuote } from 'components/VanityHeros/Heros'

export interface VanityCoverShopHeaderProps {
	settings: SiteSettings
}

export const VanityCoverShopHeader = (props: VanityCoverShopHeaderProps) => {
	const title = props.settings.heroSettings?.title || 'Join the Sleep Fitness Movement'
	const subtitle = props.settings.heroSettings?.description || []
	const sources = props.settings.heroSettings?.sources ?? []
	const sale = props.settings.saleName

	if (sale === 'Tim Ferriss Sale') {
		return (
			<div>
				<FullHeroWithQuote
					sources={sources}
					title={title}
					subtitle={subtitle}
				/>
			</div>
		)
	}
	return null
}
