import { FC, useRef, useState } from 'react'
import styles from './Dropdown.module.scss'
import { DropdownOption, DropdownProps } from './Dropdown.types'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import { useOnClickOutside } from 'components/_hooks/useOnClickOutside'

export const Dropdown: FC<DropdownProps> = (props) => {
	const { options, selectedOptionKey, defaultOptionKey, onSelect, name, placeholder } = props

	const [selectedOption, setSelectedOption] = useState(selectedOptionKey || defaultOptionKey || '')
	const [isOpen, setIsOpen] = useState(false)

	const containerRef = useRef(null)

	useOnClickOutside(containerRef, () => setIsOpen(false))

	const handleSelect = (key: string) => {
		setSelectedOption(key)
		onSelect(key)
		setIsOpen(false)
	}

	const _selectedOption = selectedOptionKey || selectedOption

	return (
		<div
			className={styles.container}
			ref={containerRef}
		>
			<DropdownOpenButton
				isOpen={isOpen}
				options={options}
				selectedOptionKey={_selectedOption}
				name={name}
				placeholder={placeholder}
				onClick={() => setIsOpen((prev) => !prev)}
			/>
			<DropdownList
				options={options}
				open={isOpen}
				selectedOptionKey={_selectedOption}
				name={name}
				handleSelect={handleSelect}
			/>
		</div>
	)
}

interface DropdownOpenButtonProps {
	options: DropdownOption[]
	selectedOptionKey: string
	name: string
	placeholder?: string
	isOpen: boolean
	onClick: () => void
}

const DropdownOpenButton: FC<DropdownOpenButtonProps> = (props) => {
	const { options, selectedOptionKey, isOpen, onClick, name, placeholder } = props
	const selectedOption = options?.find((option) => option.key === selectedOptionKey)

	return (
		<Button.Empty
			id={`${name}-button`}
			ariaLabel={`${name} dropdown select`}
			ariaHasPopup={`listbox`}
			ariaExpanded={isOpen}
			ariaControls={`${name}-list`}
			role={'combobox'}
			className={styles.select_button}
			onClick={onClick}
		>
			{selectedOption && (selectedOption.labelMain || selectedOption.labelSub) ? (
				<>
					{selectedOption.labelMain && <span className={styles.label_main}>{selectedOption.labelMain}</span>}
					{selectedOption.labelSub && <span className={styles.label_sub}>{selectedOption.labelSub}</span>}
				</>
			) : (
				<span className={styles.label_sub}>{placeholder}</span>
			)}
			<span className={styles.icon_wrapper}>
				<Icon
					name={'ChevronDownLight'}
					color={'#a0a0a0'}
					size={16}
					className={styles.chevron}
				/>
			</span>
		</Button.Empty>
	)
}

interface DropdownListProps {
	options: DropdownOption[]
	open: boolean
	selectedOptionKey: string
	name: string
	handleSelect: (key: string) => void
}

const DropdownList: FC<DropdownListProps> = (props) => {
	const { options, open, selectedOptionKey, handleSelect, name } = props
	return (
		<ul
			className={styles.dropdown_list}
			role={'listbox'}
			id={`${name}-list`}
			style={{
				visibility: open ? 'visible' : 'hidden',
				opacity: open ? 1 : 0,
				transform: open ? 'translateY(0)' : 'translateY(-0.5rem)',
			}}
		>
			{options.map((option) => (
				<li
					key={option.key}
					className={styles.dropdown_item}
				>
					<input
						type={'radio'}
						id={option.key}
						name={name}
						checked={selectedOptionKey === option.key}
						onChange={() => handleSelect(option.key)}
						className={'vh'}
					/>
					<label
						htmlFor={option.key}
						className={styles.dropdown_label}
						data-selected={selectedOptionKey === option.key}
					>
						<span className={styles.label_main}>{option.labelMain}</span>
						<span className={styles.label_sub}>{option.labelSub}</span>
						<span
							className={styles.icon_wrapper}
							style={{ visibility: selectedOptionKey === option.key ? 'visible' : 'hidden', opacity: selectedOptionKey === option.key ? 1 : 0 }}
						>
							<Icon
								name={'CheckLight'}
								color={'#1a3ebb'}
								size={16}
							/>
						</span>
					</label>
				</li>
			))}
		</ul>
	)
}
