import { FC } from 'react'
import styles from './CardSelection.module.scss'
import { CardSelectionProps } from './types'
import { CardSelectionItem } from 'components/Phantom/_shop/CardSelectionItem'
import { CardSelectionItemProps } from 'components/Phantom/_shop/CardSelectionItem/types'
import cx from 'classnames'
import { SelectionKey } from 'shop/Shop.types'

export const CardSelection: FC<CardSelectionProps> = (props) => {
	const { onChange, options, error, selectionKey, currentSelections, parentId } = props

	const handleChange = (option: CardSelectionItemProps, value: number) => {
		if (selectionKey) {
			onChange(selectionKey, option.key)
		} else {
			onChange(parentId as SelectionKey, option.id)
		}
	}

	if (!options) return null

	return (
		<div
			className={cx(styles.container, { pod_container: parentId === 'pod-model' || selectionKey === 'pod-model', autopilot_container: parentId === 'auto-pilot' || selectionKey === 'auto-pilot' })}
			style={{ gridTemplateColumns: getGridLayout(options) }}
		>
			{options.map((option: CardSelectionItemProps, i: number) => {
				const isSelected = () => {
					if (currentSelections) {
						return currentSelections[selectionKey] === option.key
					}

					return props.currentSelection === option.id
				}

				return (
					<label
						key={`${option.key}${i}`}
						aria-label={option.key}
					>
						<input
							type="radio"
							name="card-selection"
							value={option.key}
							className="vh"
							onChange={() => handleChange(option, i)}
							checked={isSelected()}
						/>
						<CardSelectionItem
							{...option}
							error={error}
							selected={isSelected()}
						/>
					</label>
				)
			})}
		</div>
	)
}

const getGridLayout = (options: CardSelectionItemProps[]) => {
	if (options.length === 0) return ''

	// If it's even, we want 2 columns because we don't want a blank space
	if (options.length % 2 === 0) return '1fr 1fr'

	// Since we can fit 3 items in a row, we want to use 3 columns
	if (options.length % 3 === 0) return 'repeat(auto-fit, minmax(0, 1fr))'

	// But a number like 5 or 7 that doesn't fit into 2 or 3 should just be 1 column
	return '1fr'
}
